import { Action, createReducer, on } from '@ngrx/store';
import { FirestoreActions } from '.';
import { DispatchTag } from '../../shared/models/dispatch-tag.model';
import { FirebaseUser } from 'src/app/shared/models/firebase-user.model';
import { AccountUser } from 'src/app/shared/models/account-user.model';

export interface FirestoreState {
  dispatchTags: DispatchTag[];
  users: FirebaseUser[];
  accountUsers: AccountUser[];
}

export const initialState: FirestoreState = {
  dispatchTags: [],
  users: [],
  accountUsers: [],
};

export const firestoreReducer = createReducer(
  initialState,
  on(FirestoreActions.dispatchTagChanged, (state, action) => ({
    ...state,
    dispatchTags: action.tags,
  })),
  on(FirestoreActions.usersChanged, (state, action) => ({
    ...state,
    users: action.users,
  })),
  on(FirestoreActions.accountUsersChanged, (state, action) => ({
    ...state,
    accountUsers: action.accountUsers,
  })),
);

export function reducer(state: FirestoreState, action: Action) {
  return firestoreReducer(state, action);
}
