import { Action, createReducer, on } from '@ngrx/store';
import { TranslationsActions } from '.';
import { Translation } from '../../shared/models/translation.model';

export interface TranslationsState {
  translations: Translation[];
  languageCode: string;
  isLoadingInDialog: boolean;
}

export const initialState: TranslationsState = {
  translations: [],
  languageCode: 'da',
  isLoadingInDialog: false,
};

export const driverReducer = createReducer(
  initialState,
  on(TranslationsActions.translationDialogInit, (state, action) => ({
    ...state,
    translations: [],
  })),
  on(TranslationsActions.translationDialogDestroyed, (state, action) => ({
    ...state,
    translations: [],
    languageCode: 'da',
    isLoadingInDialog: false,
  })),
  on(TranslationsActions.translationsFetched, (state, action) => ({
    ...state,
    translations: action.translations,
  })),
  on(TranslationsActions.translationLanguageChanged, (state, action) => ({
    ...state,
    languageCode: action.languageCode,
  })),
  on(TranslationsActions.translationCreateSubmitButtonClicked, (state, action) => ({
    ...state,
    isLoadingInDialog: true,
  })),
  on(TranslationsActions.translationEditSubmitButtonClicked, (state, action) => ({
    ...state,
    isLoadingInDialog: true,
  })),
  on(TranslationsActions.translationCreated, (state, action) => ({
    ...state,
    isLoadingInDialog: false,
  })),
  on(TranslationsActions.translationUpdated, (state, action) => ({
    ...state,
    isLoadingInDialog: false,
  })),
);

export function reducer(state: TranslationsState, action: Action) {
  return driverReducer(state, action);
}
